<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="WP User Access" page-icon="mdi-lock">
    <g-list-page
        :headers="headers"
        :show-add-button="false"
        sort="site_url"
        stateendpoint="wpAccess.wpSites"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButtons"
        @loadData="loadData"
        @editItem="editItem"
        @restoreItem="restoreItem"
        @editItemNewPage="skippedItem"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay"/>
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <v-select
                :items="authentications"
                v-model="isAuthenticated"
                @change="authenticationHandler()"
                label="Select is Authenticated?"
                dense
                outlined
                clearable
            />
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <add-button
                text="Sync Authentication"
                icon="mdi-cloud-sync"
                :toolbar="true"
                @onButtonClick="syncWPAccess({}, false)"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <component
        is="WPAccessPopup"
        ref="component"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import WPAccessPopup from "../components/popups/WPAccessPopup";
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import PageOverlay from "../components/PageOverlay";
import ListMixins from "../components/mixins/list/List";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import AddButton from "../components/buttons/AddButton";
import GPSearch from "../components/GPSearch";

export default {
  name: 'WPAccess',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - WP Access | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  },
  components: {
    GPSearch,
    AddButton,
    ConfirmPopup,
    PageOverlay,
    GListPage,
    GCard,
    WPAccessPopup
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Site URL',
          value: 'site_url'
        },
        {
          text: 'WP Username',
          value: 'wpUsername'
        },
        {
          text: 'Is Authenticated',
          value: 'wpToken'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      actions: {
        load: 'wpAccess/load',
        update: 'wpAccess/update',
        sync: 'wpAccess/sync',
        restore: 'wpAccess/reset',
        skipped: 'wpAccess/skipped',
      },
      actionButtons: [
        {
          category: 'edit',
          icon: 'mdi-lock-open',
          color: '',
          text: 'WP Access',
          clickHandler: 'editItem'
        },
        {
          category: 'reset',
          icon: 'mdi-reload',
          color: '',
          text: 'Reset WP Access',
          clickHandler: 'restoreItem'
        },
        {
          category: 'skipped',
          icon: 'mdi-pause',
          color: '',
          text: 'Skipped WP Access',
          clickHandler: 'editItemNewPage'
        }
      ],
      authentications: [
        {
          text: "Skipped",
          value: 100
        },
        {
          text: "Yes",
          value: 200
        },
        {
          text: "No",
          value: 300
        }
      ],
      isAuthenticated: null
    }
  },
  methods: {
    async syncWPAccess(item, confirm = false) {
      this.confirmEvent = this["syncWPAccess"];
      this.confirmTxt = "Are you sure to sync authorizations?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.sync, item);
    },
    authenticationHandler() {
      this.currentPaging.param1 = this.isAuthenticated;
      this.loadData({isPagingReset: true});
    },
    async passwordHandler(item) {
       await this.handleItem(this.actions.update, item, false);
    },
    async resetWPPasswordHandler(item, confirm = false) {
      this.confirmEvent = this["resetWPPasswordHandler"];
      this.confirmTxt = "Are you sure to reset WP access username and password?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      const id = item.id;
      await this.handleDestroy(this.actions.restore, id, false);
    }
  }
}
</script>
